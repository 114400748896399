/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    img: "img",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://litox9.files.wordpress.com/2013/02/biblioteca.jpeg"
  }, React.createElement(_components.img, {
    src: "http://litox9.files.wordpress.com/2013/02/biblioteca.jpeg?w=150",
    alt: "biblioteca"
  })), "Tengo el placer de presentar hoy la creación de la biblioteca de este blog, mi biblioteca personal. En ella voy a subir proyectos que voy haciendo y los libero con una licencia libre. Por ahora está un poco vacía, sólo he subido el proyecto final de carrera que hice en la técnica, pero poco a poco la iré rellenando. Para acceder a ella sólo tenéis que pinchar en la barra de menús, donde pone ", React.createElement(_components.em, null, React.createElement(_components.a, {
    href: "http://litox9.wordpress.com/mi-biblioteca/"
  }, "Mi biblioteca")), "."), "\n", React.createElement(_components.p, null, "Respecto del proyecto trata sobre redes de sensores inalámbricas conectadas directamente a Internet mediante el protocolo IPv6, espero que a alguien le sea útil."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
